<template>
  <div>
    <Toolbar :handle-reset="resetForm" :handle-submit="onSubmitVenueForm" />
    <VenueForm
      :errors="violations"
      :values="item"
      ref="createForm"
      refForm="createForm"
      :loading="isLoading"
      :handle-reset="resetForm"
      :handle-submit="onSubmitVenueForm"
    />
    <Loading :visible="isLoading" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { createHelpers } from "vuex-map-fields";
import VenueForm from "../../components/venue/Form.vue";
import Loading from "../../components/Loading";
import Toolbar from "../../components/Toolbar";
import CreateMixin from "../../mixins/CreateMixin";
import { mapGetters } from "vuex";
import DocumentMixin from "../../mixins/DocumentMixin";

const servicePrefix = "Venue";

const { mapFields } = createHelpers({
  getterType: "venue/getField",
  mutationType: "venue/updateField",
});

export default {
  name: "VenueCreate",
  servicePrefix,
  mixins: [CreateMixin, DocumentMixin],
  components: {
    Loading,
    Toolbar,
    VenueForm,
  },
  data() {
    return {
      item: {
        address: {
          type: null,
          streetNr: null,
          additional: null,
          zipCode: null,
          city: null,
        },
        image: null,
      },
    };
  },
  computed: {
    ...mapFields(["error", "isLoading", "created", "deleted", "violations"]),
    ...mapGetters("topic", ["find"]),
  },
  methods: {
    ...mapActions("venue", {
      create: "create",
    }),
    onSubmitVenueForm() {
      if (this.$refs.createForm.uploadImage) {
        this.uploadDocument(this.$refs.createForm.uploadImage);
      } else {
        this.onSendForm();
      }
    },
  },
  watch: {
    uploadedDocument() {
      if (!this.uploadedDocument) {
        return;
      }
      this.item.image = this.uploadedDocument["@id"];
      this.onSendForm();
    },
  },
};
</script>
